import { Box } from '@material-ui/core'
import EditViewWrapper from 'components/EditViewWrapper'
import { renderBankDetails } from 'pages/BankDetails/BankDetails'
import { renderContactData } from 'pages/ContactData/ContactData'
import { renderDesiredDate } from 'pages/DesiredDate/DesiredDate'
import { renderInstallationDetails } from 'pages/InstallationDetails/InstallationDetails'
import { renderTelephoneConfigurationView } from 'pages/TelephoneConfigurationView/TelephoneConfigurationView'
import { useTranslation } from 'react-i18next'
import { OnEditClickTypes, ViewType } from 'store/GeneralState/GeneralState.reducer'
import { useUserInfo } from 'utils/UserInfoContext'
import { useEditReducer } from './useEditReducer'

type Entry = {
    title: string
    type: ViewType
    content: JSX.Element
}

const Edit = (): JSX.Element => {
    const [userInfo] = useUserInfo()
    const { t } = useTranslation()

    const {
        loadState,
        onBack,
        disabledBack,
        onSubmit,
        errors,
        editType,
        editDesiredDate,
        editEarliestDatePossible,
        setEditDesiredDate,
        setEditEarliestDatePossible,
        formatDateString,
        maxDate,
        minDate,
        shouldDisableDate,
        editBankDetails,
        customizeJsData,
        showTwoConsentsIBAN,
        consentLabel,
        consentLabel2,
        editInLineErrors,
        invoiceOptions,
        dayOfTransferOptions,
        disabledSubmit,
        setEditAccountHolderCity,
        setEditAccountHolderHouseNumber,
        setEditAccountHolderLastName,
        setEditAccountHolderName,
        setEditAccountHolderSalutation,
        setEditAccountHolderStreet,
        setEditAccountHolderTitle,
        setEditAccountHolderZip,
        setEditIban,
        setEditDifferentAccountHolder,
        setEditAccountType,
        setEditAccountHolderAdditionalAddrInfo,
        setEditAccountHolderCountryAddr,
        setEditInvoiceSend,
        editSelectedInvoiceSendOption,
        setEditConsent,
        setEditConsent2,
        setEditBankDetailsPartial,
        setEditBankDetails,
        setEditDayOfTransfer,
        editDayOfTransfer,
        availability,
        b2b,
        editContactData,
        setEditContactDataPartial,
        setEditContactData,
        editInstallationDetails,
        generalState,
        convertHouseConnectionOptionToOptionItemContent,
        convertJunctionBoxOptionToOptionItemContent,
        convertOpticFiberOptionToOptionItemContent,
        convertYoutubeLinkToID,
        onChange,
        submitButtonText,
        editConfigurationMapState,
        convertOptionToOptionItem,
        setConfigurationMapCheckState,
        setConfigurationMapRadioState,
        setEditConfigurationMapState,
        submitButtonTextAlt,
        setEditContactAddress,
        setEditBankAddress,
        setEditAccountHolderCompany,
        setEditAccountHolderCompanyLegalForm,
        displayDeliveryAddress,
    } = useEditReducer()

    const pickRender = (): Entry => {
        switch (editType) {
            case OnEditClickTypes.ADDRESS:
                return {
                    title: t('editPage.headerAddress'),
                    content: renderContactData({
                        availability,
                        inLineErrors: editInLineErrors,
                        b2b,
                        contactData: editContactData,
                        customizeJsData,
                        errors,
                        setContactDataPartial: setEditContactDataPartial,
                        setContactData: setEditContactData,
                        setEditContactAddress: setEditContactAddress,
                        userInfo,
                        displayDeliveryAddress,
                        installationDetails: editInstallationDetails,
                        onChangeInstallationFields: onChange,
                    }),
                    type: ViewType.CONTACT_DATA,
                }
            case OnEditClickTypes.INSTALLATION:
                return {
                    title: t('editPage.headerInstallation'),
                    content: renderInstallationDetails({
                        convertHouseConnectionOptionToOptionItemContent,
                        convertJunctionBoxOptionToOptionItemContent,
                        convertOpticFiberOptionToOptionItemContent,
                        convertYoutubeLinkToID,
                        customizeJsData,
                        generalState,
                        installationDetails: editInstallationDetails,
                        onChange: onChange,
                        submitButtonText,
                    }),
                    type: ViewType.INSTALLATION_DETAILS,
                }
            case OnEditClickTypes.BANK:
                return {
                    title: t('editPage.headerBank'),
                    content: renderBankDetails({
                        setAccountType: setEditAccountType,
                        errors,
                        inLineErrors: editInLineErrors,
                        setAccountHolderCity: setEditAccountHolderCity,
                        setAccountHolderHouseNumber: setEditAccountHolderHouseNumber,
                        setAccountHolderLastName: setEditAccountHolderLastName,
                        setAccountHolderName: setEditAccountHolderName,
                        setAccountHolderSalutation: setEditAccountHolderSalutation,
                        setAccountHolderStreet: setEditAccountHolderStreet,
                        setAccountHolderTitle: setEditAccountHolderTitle,
                        setAccountHolderZip: setEditAccountHolderZip,
                        setAccountHolderCompany: setEditAccountHolderCompany,
                        setAccountHolderCompanyLegalForm: setEditAccountHolderCompanyLegalForm,
                        setIban: setEditIban,
                        setConsent: setEditConsent,
                        setConsent2: setEditConsent2,
                        consent: editBankDetails.consentChecked,
                        setDifferentAccountHolder: setEditDifferentAccountHolder,
                        setAccountHolderAdditionalAddrInfo: setEditAccountHolderAdditionalAddrInfo,
                        setAccountHolderCountryAddr: setEditAccountHolderCountryAddr,
                        setInvoiceSend: setEditInvoiceSend,
                        selectedInvoiceSendOption: editSelectedInvoiceSendOption,
                        setBankDetailsPartial: setEditBankDetailsPartial,
                        setEditBankDetails,
                        dayOfTransfer: editDayOfTransfer,
                        bankDetails: editBankDetails,
                        showTwoConsentsIBAN,
                        consentLabel,
                        consentLabel2,
                        invoiceOptions,
                        dayOfTransferOptions,
                        customizeJsData,
                        setDayOfTransfer: setEditDayOfTransfer,
                        setEditBankAddress: setEditBankAddress,
                    }),
                    type: ViewType.BANK_DETAILS,
                }
            case OnEditClickTypes.DESIREDDATE:
                return {
                    title: t('editPage.headerDesiredDate'),
                    content: renderDesiredDate({
                        formatDateString,
                        maxDate,
                        minDate,
                        onDateChange: setEditDesiredDate,
                        setEarliestDatePossible: setEditEarliestDatePossible,
                        shouldDisableDate,
                        currentView: ViewType.DESIRED_DATE,
                        desiredDate: editDesiredDate,
                        earliestDatePossible: editEarliestDatePossible,
                        userInfo,
                        customizeJsData,
                    }),
                    type: ViewType.DESIRED_DATE,
                }
            case OnEditClickTypes.TELEPHONE:
                return {
                    title: t('editPage.headerTelephone'),
                    content: renderTelephoneConfigurationView({
                        configurationMapState: editConfigurationMapState,
                        convertOptionToOptionItem,
                        customizeJsData,
                        setConfigurationMapCheckState,
                        setConfigurationMapRadioState,
                        userInfo,
                        setEditConfigurationMapState,
                    }),
                    type: ViewType.LANDLINE_CONTRACT_OPTIONS,
                }
            default:
                return { title: '', content: <Box>MOVE ALONG, NOTHING TO SEE HERE</Box>, type: ViewType.ORDER_OVERVIEW }
        }
    }
    const data = pickRender()
    return (
        <EditViewWrapper
            header={data.title}
            viewType={data.type}
            errorCategory={data.type}
            disabledSubmit={disabledSubmit}
            loadState={loadState}
            errors={errors}
            onBack={onBack}
            onSubmit={onSubmit}
            disabledBack={disabledBack}
            submitButtonTextAlt={submitButtonTextAlt}
        >
            {data.content}
        </EditViewWrapper>
    )
}

export default Edit
