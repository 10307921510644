import gql from 'graphql-tag'

export default gql`
    query LoadClientData($id: String!) {
        loadClientData(id: $id) {
            id
            salutation
            name
            lastName
            company
            zipcode
            city
            street
            housenumber
            telephoneNumber
            email
            website
            management
            register
            registerCourt
            taxID
            revocationFormPDFPath
            linkAGB
            linkImpressum
            linkDataPrivacy
            salesPartner
            salesPartnerFullName
            useDesiredDate
            state
            clientID
            logo {
                name
                uploadDate
                size
            }
        }
    }
`
