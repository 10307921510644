import gql from 'graphql-tag'

export default gql`
    query LoadOrderProcessData($type: String!) {
        loadOrderProcessData(type: $type) {
            globalConfiguration {
                title
                telephoneNumber
                enableLoadingClient
                showBasketInfoIcons
                useCustomCombiDefaultSelection
                enablePresentation
                enableHardwareSelection
                logoClickWebsite
                productGroups
                footer {
                    i18Key
                    link
                }
                icons {
                    header
                    footer
                    thankYou
                }
                infoBox {
                    path
                    messages {
                        message
                        condition
                    }
                }
                vzfCheckOwnButton
                enableAddressAddition
                enableAddressDistrict
                disableStartLogo
            }
            voucherConfiguration {
                enableAutomatic
                enableManualPage
            }
            portabilityConfiguration {
                contractHolderMaximumNumber
                portabilityMaximumNumber
                previousProvider
                previousContractHolder
                previousContractAddress
                previousContractTermination
                showPortability {
                    identifier
                    wishValue
                }
                B2BpreviousContractTerminationLink
                B2CpreviousContractTerminationLink
            }
            productCategoriesConfiguration {
                enablePage
                categoryDependencies {
                    id
                    config {
                        excluded
                        required
                    }
                }
            }
            telephoneOptions {
                enablePage
                enablePortabilityPage
                telephoneConfiguration {
                    identifier
                    link {
                        text
                        url
                    }
                    helpText
                    required
                    type
                    displayType
                    options {
                        labelKey
                        value
                        preselected
                    }
                    condition {
                        identifier
                        value
                    }
                    render {
                        identifier
                        link {
                            text
                            url
                        }
                        helpText
                        required
                        type
                        displayType
                        options {
                            labelKey
                            value
                            preselected
                        }
                        condition {
                            identifier
                            value
                        }
                        noMarginBottom
                        marginTop
                        titleHidden
                    }
                    noMarginBottom
                    marginTop
                    titleHidden
                }
            }
            installationConfiguration {
                enablePage
                minInstallationDate
                maxInstallationDate
            }
            installationDetailsConfiguration {
                enablePage
                i18Key
                displayInContactPage
                appartmentData {
                    floorInformation
                    floorInformationRequired
                    flatPosition
                    flatPositionRequired
                    flatNumber
                    flatNumberRequired
                }
                junctionBox {
                    identifier
                    title
                    preselected
                    options {
                        title
                        badgeIdentifier
                        information
                    }
                }
                houseConnection {
                    identifier
                    title
                    preselected
                    options {
                        title
                        badgeIdentifier
                        information
                    }
                }
                opticFiber {
                    identifier
                    title
                    preselected
                    options {
                        title
                        badgeIdentifier
                        information
                    }
                }
                installationService {
                    title
                    subtitle
                    video
                    option {
                        title
                        subtitle
                        cost
                        information
                    }
                }
            }
            bankDetails {
                enablePage
                consentLabel {
                    selfAccountHolder
                    differentAccountHolder
                }
                useSecondConsentIBAN
                useInvoiceFee {
                    feeId
                    visibleB2B
                }
                useTransferFee {
                    feeId
                    visibleB2B
                }
                displayIBAN
                displayTransfer
                displayDayOfTransfer
                preselected
                invoiceSend {
                    display
                    enableOptionMail
                    enableOptionEmail
                }
                salutationOptionsAccount
            }
            orderOverview {
                vzf {
                    header
                    subheader
                    helperText
                    checkboxes
                }
                legalTerms {
                    header
                    subheader
                    helperText
                    checkboxes {
                        value
                        isDisplayedInB2B
                        displayProdDBInfoIcon
                    }
                }
                newsletters {
                    header
                    subheader
                    helperText
                    checkboxes
                }
                sendOverView {
                    header
                    subheader
                    helperText
                    checkboxes
                }
                showPeriodOfNoticeInfo
                showRightOfWithdrawalInfo
                showTransferMonthlyTitle
                isEditable
            }
            noProductAvailable {
                haveLink
                link
            }
            vzfConfiguration {
                optionalFees {
                    name
                    value
                }
                footNoteText {
                    name
                    value
                }
                introText
            }
            contactDataViewConfiguration {
                enablePage
                activateSalesPartnerEmail
                salesPartnerEmail
                enableCompanyTaxID
                enableLegalForm
                enableCompanyRegisterEntry
                enableCompanyLocation
                enableAlternativeLegalForm
                existingCustomerForm
                salutationOptionsPersonal
                salutationOptionsDeliver
                salutationOptionsBilling
                useAutoCompleteDeliveryAddress
                useAutoCompleteBillingAddress
                showCountryFieldDeliveryAddress
                showCountryFieldBillingAddress
                deliveryAddressConfiguration {
                    enabled
                    enabledDetectOwnHardware
                    ownHardwareID
                }
            }
            orderConfirmationConfiguratiuon {
                enableTouchPoints
                touchPoints {
                    label
                    hasInput
                }
            }
            optList
            crossSelling {
                enabledPage
                rules {
                    target
                    required
                }
            }
        }
    }
`
